<template>
  <div class="crm f-flex">
    <div class="crm-tab f-flex">
      <div
        :class="{
          'crm-tab-li': true,
          'crm-cur': initial.betType == item.value,
        }"
        @click="switchPlayEvent(item.value)"
        v-for="(item, index) in initial.play"
        :key="index"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="crm-tips" v-if="initial.filter">
      {{ initial.filter.minMoney }}/{{ initial.filter.maxMoney }}/{{
        initial.filter.subMaxMoney
      }}
    </div>
  </div>
  <!--下注操作區-->
  <CommonBetBottom
    :quick="initial.quick"
    :disabled="store.state.game.config.status != 1"
    :amount="initial.single"
    @on-change-bet-amount="emitChangeAmountEvent"    
    @on-confirm-bet="emitConfirmBetEvent"
    @on-clear-bet="emitClearBetEvent"
    @on-switch-quick-bet="emitSwitchQuickBetEvent"
  ></CommonBetBottom>
  <div class="s-box f-flex" v-if="false">
    <div class="s-t">快填金額：</div>
    <div><input class="ant-input" type="number" /></div>
    <div class="f-plr10">
      <select class="ant-select" disabled="disabled">
        <option value="0">请選择</option>
        <option value="saab">Saab</option>
        <option value="opel">Opel</option>
        <option value="audi">Audi</option>
      </select>
    </div>
  </div>
  <!--下註選项-->
  <div v-if="loading" class="loading"></div>
  <LhcBetNumberQuick
    :quick="initial.quick"
    :gameType="initial.gameType"
    :numbers="initial.numbers"
    :extNumbers="initial.extNumbers"
    :disabled="store.state.game.config.status != 1"
    @quick-panel-click="emitQuickPanelClickEvent"
    @quick-panel-radio="emitQuickPanelRadioEvent"
    @check-number-click="emitCheckNumberClickEvent"
  />
  <!--下注操作區-->
  <CommonBetBottom
    :quick="initial.quick"
    :disabled="store.state.game.config.status != 1"
    :amount="initial.single"
    @on-change-bet-amount="emitChangeAmountEvent"    
    @on-confirm-bet="emitConfirmBetEvent"
    @on-clear-bet="emitClearBetEvent"
    @on-switch-quick-bet="emitSwitchQuickBetEvent"
  ></CommonBetBottom>
  <!--手动下註組件-->
  <LhcManualQuick
    v-if="!initial.quick"
    @quick-bet-click="emitQuickBetClickEvent"
  />
  <!--確認订單提交弹窗 -->
  <LhcBetConfirm
    @on-confirm-success="emitConfirmSuccessEvent"
    @on-confirm-cancel="emitConfirmCancelEvent"
    :title="initial.title"
    ref="confirmBet"
    :filter="initial.filter"
  />
</template>

<script>
import LhcManualQuick from "@/views/liuhecai/components/lhc-manual-quick.vue";
import LhcBetNumberQuick from "@/views/liuhecai/components/lhc-bet-number-quick.vue";
import LhcBetConfirm from "@/views/liuhecai/components/lhc-bet-confirm.vue";
import CommonBetBottom from "@/components/common-bet-bottom.vue";
import { balls, extZhengmaNumber, playInfo } from "@/config/lhc.config";
import { ref, watch, reactive, onMounted, nextTick, computed } from "vue";
import find from "lodash.find";
import isUndefined from "lodash.isundefined";
import cloneDeep from "lodash.clonedeep";
import Message from "@/components/library/Message";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { quickPanelBet } from "@/utils/tools";
export default {
  name: "ZhengMaBet",
  components: {
    LhcManualQuick,
    LhcBetNumberQuick,
    LhcBetConfirm,
    CommonBetBottom,
  },
  setup() {
    //监听路由變化
    const route = useRoute();
    const store = useStore();
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });

    watch(
      () => [currentGame.value.code, currentGame.value.plat],
      () => {
        requestGameOddsAndFilterEvent();
      }
    );
    //初始化页面
    onMounted(() => {
      emitClearBetEvent();
      nextTick(() => {
        requestGameOddsAndFilterEvent();
      });
    });
    const loading = ref(false);
    let initial = reactive({
      title: "[正碼]-註單確認",
      gameType: "zhengma",
      quick: false,
      moldText: "快速模式",
      numbers: cloneDeep(balls),
      extNumbers: cloneDeep(extZhengmaNumber),
      quickRadio: true,
      single: null, //單註金額
      type: "正碼A盤",
      play: find(playInfo, (item) => {
        return item.key == "zhengma";
      }).list,
      betType: 3,
      limit: {
        zhengma: {},
        liangmian: {},
        bose: {},
      },
      filter: {},
    });
    //下註提交项
    let postBet = reactive({
      gameCode: store.state.app.currentGame.code,
      betType: 3,
      betTypeName: "正碼A盤",
      contents: [],
    });
    //弹窗組件
    const confirmBet = ref(null);
    //快速模式 切换
    const emitSwitchQuickBetEvent = () => {
      initial.quick = !initial.quick;
      initial.moldText = initial.quick ? "一般模式" : "快速模式";
      emitClearBetEvent();
    };
    //快速下單
    const emitQuickBetClickEvent = (val) => {
      //01=1 02=2 03=3
      let arr1 = val.split(" "),
        arr2 = [];
      if (arr1.length > 0) {
        arr1.forEach((element) => {
          if (element.indexOf("=") == -1) {
            return false;
          }
          arr2.push({
            ball: element.split("=")[0],
            value: element.split("=")[1],
          });
        });
      }
      if (arr2.length > 0) {
        arr2.forEach((item) => {
          initial.numbers.forEach((items) => {
            if (item.ball != items.ball) {
              return false;
            }
            items.value = item.value;
          });
        });
      }
    };
    const emitQuickPanelClickEvent = (name) => {
      quickPanelBet(initial.numbers, name, initial.quickRadio);
    };
    const emitQuickPanelRadioEvent = (check) => {
      initial.quickRadio = check;
    };
    const emitConfirmSuccessEvent = (data) => {
      //data 註單信息 左侧最新註單显示  push 用
      emitClearBetEvent();
    };
    //確認下單
    const emitConfirmBetEvent = (amount) => {
      postBet.betTypeName = initial.type;
      postBet.betType = initial.betType;
      if (!initial.quick) {
        let items = initial.numbers.concat(initial.extNumbers);
        let checked = find(items, (item) => {
          return item.value && item.value != "";
        });
        if (isUndefined(checked)) {
          Message({ text: "请输入下註金額" });
          return;
        }
        items.forEach((elment) => {
          if (elment && elment.value) {
            postBet.contents.push({
              typeId: initial.betType,
              odds: elment.odds,
              tabType: elment.tabType,
              betMoney: Number(elment.value),
              nums: elment.ball,
            });
          }
        });
      } else {
        let checked = find(initial.numbers, (item) => {
          return item.checked == true;
        });
        if (isUndefined(checked)) {
          Message({ text: "请選择下註项投註" });
          return;
        }
        initial.numbers.forEach((elment) => {
          if (elment && elment.checked) {
            postBet.contents.push({
              typeId: initial.betType,
              odds: elment.odds,
              tabType: elment.tabType,
              betMoney: Number(amount),
              nums: elment.ball,
            });
          }
        });
      }
      confirmBet.value.open(postBet);
    };
    //清除下單
    const emitClearBetEvent = () => {
      initial.numbers.forEach((items) => {
        items.checked = false;
        items.value = "";
      });
      initial.extNumbers.forEach((items) => {
        items.checked = false;
        items.value = "";
      });
      initial.single = null;
      emitConfirmCancelEvent();
    };
    const emitConfirmCancelEvent = () => {
      postBet.contents = [];
    };
    //玩法切换
    const switchPlayEvent = (type) => {
      initial.betType = type;
      initial.type = find(initial.play, (item) => {
        return item.value === type;
      }).label;
      initial.quick = false;
      initial.moldText = initial.quick ? "一般模式" : "快速模式";
      /**
       * TODO 賠率请求
       */
      emitClearBetEvent();
      requestGameOddsAndFilterEvent();
    };
    //获取游戏賠率及限制
    const requestGameOddsAndFilterEvent = () => {
      let params = {
        plat: store.state.app.currentGame.plat,
        gameCode: store.state.app.currentGame.code,
        betType: initial.betType,
      };
      loading.value = true;
      store
        .dispatch("game/requestGameOddAndFilterAction", params)
        .then((res) => {
          loading.value = false;
          if (res.code == 0) {
            initial.numbers = res.res.numbers.slice(0, 49);
            initial.extNumbers = res.res.numbers.slice(49, 999);
            initial.filter = res.res.filter;
          }
        })
        .then(() => {
          emitClearBetEvent();
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const emitCheckNumberClickEvent = (data) => {
      if (!data) {
        return;
      }
      initial.numbers.forEach((items) => {
        if (data.ball != items.ball) {
          return false;
        }
        items.checked = data.checked;
      });
    };
    const emitChangeAmountEvent = (amt) => { initial.single = amt; };    
    return {
      emitChangeAmountEvent,
      store,
      loading,
      initial,
      postBet,
      confirmBet,
      emitSwitchQuickBetEvent,
      emitQuickBetClickEvent,
      emitQuickPanelClickEvent,
      emitQuickPanelRadioEvent,
      emitConfirmSuccessEvent,
      emitConfirmCancelEvent,
      emitConfirmBetEvent,
      emitClearBetEvent,
      switchPlayEvent,
      requestGameOddsAndFilterEvent,
      emitCheckNumberClickEvent
    };
  },
};
</script>

<style scoped lang="less">
:deep(.xtx-carousel) .carousel-btn.prev {
  left: 5px;
}
:deep(.xtx-carousel) .carousel-btn.next {
  right: 5px;
}
</style>
